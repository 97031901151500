import { fetchQuery, graphql, type GraphQLTaggedNode } from 'react-relay';
import { sessionStorage, localStorage } from 'dibs-browser-storage';
import { getUserGeoInfo, USER_GEO_INFO } from 'dibs-regional-info/exports/regionalInfoHelpers';
import { editAccountPartialShippingAddress } from '../mutations/editAccountPartialShippingAddressMutation';
import { type AuthResponseType } from '../authModal/authentication/authTypes';

import { type saveUserAddressQuery as QueryType } from './__generated__/saveUserAddressQuery.graphql';

const saveUserAddressQuery: GraphQLTaggedNode = graphql`
    query saveUserAddressQuery($userId: String!) {
        viewer {
            user(userId: $userId) {
                addressBook {
                    addressList {
                        __typename
                    }
                }
            }
        }
    }
`;

export async function saveUserAddress(
    authResponse: Pick<AuthResponseType, 'isNewUser' | 'userId' | 'environment'>
): Promise<void> {
    const { userId, isNewUser, environment } = authResponse;
    if (!isNewUser && userId) {
        const userGeoInfo = getUserGeoInfo();
        if (userGeoInfo?.countryCode) {
            localStorage.deleteItem(USER_GEO_INFO);
            sessionStorage.deleteItem(USER_GEO_INFO);
        }
        const response = await fetchQuery<QueryType>(environment, saveUserAddressQuery, {
            userId,
        }).toPromise();
        const hasUserAddress = response?.viewer.user?.addressBook?.addressList?.length;
        if (!hasUserAddress) {
            await editAccountPartialShippingAddress(environment, {
                userId,
                address: {
                    country: userGeoInfo?.countryCode,
                    zipCode: userGeoInfo?.zipCode,
                },
            });
        }
    }
}
