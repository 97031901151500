import { type Environment, fetchQuery_DEPRECATED as fetchQuery, graphql } from 'react-relay/legacy';

import { type TrackingObject, eventNameConstants } from 'dibs-tracking';
import { getUserSessionCountryCode } from 'dibs-regional-info/exports/regionalInfoHelpers';

import { loginMutation } from '../login/loginMutation';
import { type SuccessPayload } from '../authTypes';
import {
    trackSocialAccountCreated,
    trackSocialAccountLinked,
    trackSocialAuthComplete,
} from '../authTracking';

import { SOCIAL_CASE_CONNECTION_LINKED, SOCIAL_CASE_ACCOUNT_CREATED } from '../authFlowConstants';

import { type launchLoginMutationQuery } from './__generated__/launchLoginMutationQuery.graphql';

const query = graphql`
    query launchLoginMutationQuery($userCountryCode: String!) {
        viewer {
            gdprDisplayInfo {
                isGdprApplicable(countryCode: $userCountryCode)
            }
            appDownloadDisplayInfo {
                isAppDownloadApplicable(countryCode: $userCountryCode)
            }
        }
    }
`;

export const launchLoginMutation = async ({
    onSuccess,
    onError,
    environment,
    ga = {},
    accessToken,
    user,
    clientId,
    name = '',
    marketingOptIn,
}: {
    onSuccess: (p: SuccessPayload) => void;
    onError: (e: { error: string }) => void;
    environment: Environment;
    ga?: TrackingObject;
    accessToken: string;
    user?: {
        name: {
            firstName: string;
            lastName: string;
        };
        email: string;
    };
    clientId?: string;
    name?: string;
    marketingOptIn: boolean;
}): Promise<void> => {
    try {
        const ssoType = name.toLowerCase();
        const userName = user?.name;
        const response = await fetchQuery<launchLoginMutationQuery>(environment, query, {
            userCountryCode: getUserSessionCountryCode(),
        });

        const isGdprApplicable = response.viewer?.gdprDisplayInfo?.isGdprApplicable ?? true;
        const isAppDownloadApplicable =
            response.viewer?.appDownloadDisplayInfo?.isAppDownloadApplicable || false;

        const { userLogin } = await loginMutation(
            environment,
            {
                socialUserFirstName: userName?.firstName,
                socialUserLastName: userName?.lastName,
                socialUserEmail: user?.email,
                socialClientId: clientId,
                socialToken: accessToken,
                socialNetworkName: ssoType,
                marketingOptIn: !isGdprApplicable || marketingOptIn, // automatically opt-in non-GDPR to marketing
            },
            { ga }
        );
        const { responseMessage, ...userInfo } = userLogin || {};
        const authTrackingValues = {
            ...userInfo,
            ...ga,
            ssoType,
        };

        const { EVENT_LOGIN, EVENT_SIGN_UP } = eventNameConstants;

        if (responseMessage === SOCIAL_CASE_CONNECTION_LINKED) {
            // [GROWTH-3168] social account is connected to user's existing 1stDibs account
            //@ts-ignore TODO: refactor when authTracking is written in typescript
            trackSocialAuthComplete({
                category: 'login',
                eventName: EVENT_LOGIN,
                ...authTrackingValues,
            });
            //@ts-ignore TODO: refactor when authTracking is written in typescript
            trackSocialAccountLinked(authTrackingValues);
            onSuccess({
                socialAuthSuccessType: SOCIAL_CASE_CONNECTION_LINKED,
                userInfo: userLogin,
                isAppDownloadApplicable,
            });
        } else if (responseMessage === SOCIAL_CASE_ACCOUNT_CREATED) {
            // [GROWTH-3169] new 1stDibs account is created using user's social credentials
            //@ts-ignore TODO: refactor when authTracking is written in typescript
            trackSocialAuthComplete({
                category: 'registration',
                eventName: EVENT_SIGN_UP,
                ...authTrackingValues,
            });
            //@ts-ignore function is in js file
            trackSocialAccountCreated(authTrackingValues);
            onSuccess({
                socialAuthSuccessType: SOCIAL_CASE_ACCOUNT_CREATED,
                userInfo: userLogin,
                isGdprApplicable,
                isAppDownloadApplicable,
            });
        } else {
            // user is re-logging in with an existing social account
            //@ts-ignore TODO: refactor when authTracking is written in typescript
            trackSocialAuthComplete({
                category: 'login',
                eventName: EVENT_LOGIN,
                ...authTrackingValues,
            });
            //@ts-ignore TODO: refactor when authTracking is written in typescript
            trackSocialAccountLinked(authTrackingValues);
            onSuccess({ userInfo: userLogin, isAppDownloadApplicable });
        }
    } catch (err: $TSFixMe) {
        onError({ error: err?.source?.errors?.[0] || err });
    }
};
