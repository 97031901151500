import { type AuthCopyType } from './authTypes';

/*
 * Hash function takes user email's local-part and encodes to base64.
 */
export const getAuthHash = ({ email }: { email: string | null | undefined }): string =>
    btoa((email || '').split('@')[0]);

export const getCompletionStepCopy = (copy: AuthCopyType): AuthCopyType => {
    return {
        ...copy,
        header: copy.registrationCompletionHeader,
        subheader: copy.registrationCompletion,
    };
};
