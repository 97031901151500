import { type Environment } from 'react-relay/legacy';
import { ClientCookieManager } from 'dibs-consented-storage';
import widgetLoader from 'dibs-widget-loader';
import { type TrackingObject } from 'dibs-tracking';
import { launchLoginMutation } from './launchLoginMutation';
import { type SuccessPayload } from '../authTypes';
import { trackSocialLoginEntry, trackSocialLoginError } from '../authTracking';

export const GOOGLE_ONE_TAP_COOKIE_TIMEOUT_KEY = 'oneTapDelay';
const GOOGLE_ONE_TAP_COOKIE_TIMEOUT = 1 / 12; // 2 hours (expire time is taken in days)
const GOOGLE_ONE_TAP_DISMISS_DELAY = 90000;

type LoadGoogleProps = {
    onLoad: ({ token }: { token: string | null }) => void;
    onError: (error: string) => void;
    relayEnvironment: Environment;
    buttonId: string;
    buttonOptions: {
        type?: 'standard' | 'icon';
        theme?: 'outline' | 'filled_blue' | 'filled_black';
        size?: 'large' | 'medium' | 'small';
        text?: 'signin_with' | 'signup_with' | 'continue_with' | 'signin';
        shape?: 'rectangular' | 'pill' | 'circle' | 'square';
        logo_alignment?: 'left' | 'center';
        width?: number;
        locale?: string;
        click_listener?: () => void;
    };
};

type ErrorObject = {
    error: string;
};

type LoginGoogleProps = {
    onSuccess: (payload: ObjectType) => void;
    onError: (err: ErrorObject) => void;
    environment: Environment;
    ga: TrackingObject;
    name: string;
    marketingOptIn: boolean;
};

type LoginOneTapGoogleProps = {
    onSuccess: (payload: SuccessPayload) => void;
    onError: (err: ErrorObject) => void;
    onDismissedMoment: (promptMoment: google.PromptMomentNotification) => void;
    onSkippedMoment: (promptMoment: google.PromptMomentNotification) => void;
    environment: Environment;
    ga: TrackingObject;
    name: string;
    marketingOptIn: boolean;
};

export async function loadGoogle({
    buttonId,
    onLoad,
    onError,
    relayEnvironment,
    buttonOptions,
}: LoadGoogleProps): Promise<void> {
    try {
        if (!window.google) {
            await widgetLoader.load('google', { relayEnvironment });
        }

        window.google.accounts.id.renderButton(
            document.getElementById(buttonId) as HTMLElement,
            buttonOptions
        );

        onLoad({ token: null });
    } catch (error: $TSFixMe) {
        onError(error);
    }
}

export async function loginGoogle(props: LoginGoogleProps): Promise<void> {
    const label = props?.ga.label;

    trackSocialLoginEntry({
        label,
        ssoType: 'google',
    });

    window.dibsGoogleCallback = response => {
        try {
            launchLoginMutation({
                ...props,
                accessToken: response.credential,
                clientId: response.client_id,
            });
        } catch (error: $TSFixMe) {
            trackSocialLoginError({
                ssoType: 'google',
                errorMessage: error.error,
            });
            props.onError(error);
        }
    };
}

export async function loginOneTapGoogle(props: LoginOneTapGoogleProps): Promise<void> {
    const isGoogleOneTapOnDelay = ClientCookieManager.get(GOOGLE_ONE_TAP_COOKIE_TIMEOUT_KEY);

    let googleOneTapTimeoutId: NodeJS.Timeout;

    if (!isGoogleOneTapOnDelay) {
        googleOneTapTimeoutId = setTimeout(() => {
            window.google?.accounts?.id?.cancel?.();

            ClientCookieManager.set(GOOGLE_ONE_TAP_COOKIE_TIMEOUT_KEY, 'true', {
                expires: GOOGLE_ONE_TAP_COOKIE_TIMEOUT,
            });
        }, GOOGLE_ONE_TAP_DISMISS_DELAY);
    }

    window.dibsGoogleCallback = response => {
        try {
            launchLoginMutation({
                ...props,
                accessToken: response.credential,
                clientId: response.client_id,
            });
        } catch (error: $TSFixMe) {
            props.onError(error);
        }
    };

    window.google.accounts.id.prompt(promptMoment => {
        if (promptMoment.isDismissedMoment()) {
            props.onDismissedMoment(promptMoment);
            clearTimeout(googleOneTapTimeoutId);
        }
        if (promptMoment.isSkippedMoment()) {
            // Skipped moment is triggered with FedCM enabled when:
            // * user does not have active google account in browser
            // * user closed one tap prompt
            // * after the user closed prompt and it is on the google cooldown
            props.onSkippedMoment(promptMoment);
            clearTimeout(googleOneTapTimeoutId);
        }
    });
}
