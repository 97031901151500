import { localStorage } from 'dibs-browser-storage';
import { trackEvent, eventNameConstants, interactionTypeConstants } from 'dibs-tracking';
const { EVENT_PROMO_INTERACTION } = eventNameConstants;
const { INTERACTION_TYPE_APP_DOWNLOAD } = interactionTypeConstants;

const APP_DOWNLOAD_KEY = 'appDownload';

type AppDownloadModalData = {
    timestamp: number;
    count: number;
};

const getAppDownloadData = (): AppDownloadModalData => {
    return (localStorage.getItem(APP_DOWNLOAD_KEY) as AppDownloadModalData) || {};
};
const getCount = (): number => getAppDownloadData().count || 0;
const getTimestamp = (): number => getAppDownloadData().timestamp || 0;

export const setAppDownloadOnCooldown = (): void => {
    localStorage.setItem(APP_DOWNLOAD_KEY, {
        timestamp: Date.now(),
        count: getCount() + 1,
    });
};

export const isAppDownloadOnCooldown = (): boolean => {
    const timeSinceDismissed = Date.now() - getTimestamp();
    const hour = 60 * 60 * 1000;
    const day = 24 * hour;

    switch (getCount()) {
        case 0:
            return false;
        case 1:
            return timeSinceDismissed < 2 * hour;
        case 2:
            return timeSinceDismissed < day;
        case 3:
            return timeSinceDismissed < 7 * day;
        default:
            return timeSinceDismissed < 28 * day;
    }
};

export const trackAppDownloadEvent = (launchOrigin: string = '', action: string): void => {
    const label = `${launchOrigin} - ${getCount() + 1}`;
    const trackingAction = `app download ${action}`;
    trackEvent({
        category: 'promo interaction',
        action,
        label,
        eventName: EVENT_PROMO_INTERACTION,
        interaction_type: INTERACTION_TYPE_APP_DOWNLOAD,
        step_interaction_name: trackingAction,
        trigger: label,
    });
};
