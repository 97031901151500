import { graphql, type Environment } from 'react-relay';
import commitMutation from 'relay-commit-mutation-promise';
import {
    type addPartialShippingAddressInput,
    type editAccountPartialShippingAddressMutation,
    type editAccountPartialShippingAddressMutation$data,
} from './__generated__/editAccountPartialShippingAddressMutation.graphql';

const mutation = graphql`
    mutation editAccountPartialShippingAddressMutation($input: addPartialShippingAddressInput!) {
        addPartialShippingAddress(input: $input) {
            address {
                country
            }
        }
    }
`;

export function editAccountPartialShippingAddress(
    environment: Environment,
    input: addPartialShippingAddressInput
): Promise<editAccountPartialShippingAddressMutation$data> {
    return commitMutation<editAccountPartialShippingAddressMutation>(environment, {
        mutation,
        variables: { input },
    });
}
